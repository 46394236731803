require('./utils/idle-timer.min');
window.onunload = window.onbeforeunload = function () {
    $.ajax({
        url: '/handleClose',
        type: 'GET',
        dataType: 'JSON',
        success: null
    });
};
$(function () {
    $.idleTimer({
        timeout: 1000 * 3600 * 3,
        idle: true, // events:'mousemove keydown wheel DOMMouseScroll mousewheel mousedown touchstart touchmove MSPointerDown MSPointerMove',
    });
    $(document).on("idle.idleTimer", function (event, elem, obj) {
        if (document.getElementById('logout-form')) document.getElementById('logout-form').submit();
    });

    function updateLogin() {
        $.ajax({
            url: '/search/update_login_count',
            type: 'POST',
            dataType: 'JSON',
            success: null
        });
    }

    updateLogin();
});

